/***********/
/* App.css */
/**********************************************************************************************/
/* RedWingTradingPost stylesheet.                                                             */
/*                                                                                            */    
/* Copyright (C) 2019 - 2020 Orcadia Labs LLC                                                 */   
/**********************************************************************************************/


/**********************************************************************************************/
/* General Red Wing Trading Post application                                                  */
/**********************************************************************************************/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FF0000;
}

.App-link {
  color: #09d3ac;
}


/**********/
/* Common */
/**********************************************************************************************/
/*   commonly-used styles.                                                                    */
/**********************************************************************************************/
.text-common {
  text-align : left;
}


/*************/
/* Road Sign */
/**********************************************************************************************/
/* The road sign and road sign animation.                                                     */
/**********************************************************************************************/
#roadsign-page {             /* entire roadsign page, including #roadsign    */
  display           : block;
  background-image  :  url("./roadsign/images/roadsign-fill.png");
  background-repeat : repeat;
  background-size   : 100px 33px;
  width             : 100vw;
  height            : 100vh;
}

#roadsign-container {
/*  position          : fixed; */ 
  position          : absolute; 
  top               : 0px;
  left              : 0px;
  width             : 100vw;
  max-width         : 820px;
  height            : 585px;        /* (820 / 1150) * 820         */
                                    /*  750 = max-width           */
                                    /* 1150 = image actual height */
}

/*****************************************************************/
/** A number of transitions happens at one certain pixel width: **/
/** The transformations occur at #roadsign-container.max-width  **/
/**   (1) The Road Sign transitions from bottom-to-top anchor   **/
/**   (2) The Menu Sign inversely resizes                       **/
/**   (3) The ingo image presents itself in strategic locations **/
/**   (4) The Orcadia Labs brand image will sit in upper-right  **/
/**       of available space given all the (1-3.)               **/
/*****************************************************************/
#roadsign-menu {
  position          : relative;
  display           : block;
  left              : 0;
  top               : 0;
  width             : 100%; 
} /* #roadsign-menu */


/********/
/* WIDE */
/**********************************************************************************************/
/* A description of the Road Sign as it is to be displayed on a wide viewport,                */
/* typically a desktop, laptop, tablet or large iphone.                                       */
/**********************************************************************************************/
@media all and (min-width: 750px) {           
  #roadsign {     
    position          : absolute;
    bottom            : 0px; 
    right             : 0px;
    left              : 0px;
    overflow          : hidden;  
    z-index           : 10000;     /* must be less than #roadsign-inoutgoA.zIndex */
  }
  
  #roadsign-menuContainer {
    position            : absolute;
    top                 : -136px;
    padding-top         : 90px;
    left                : 6%;
    height              : 256px;
    width               : 256px;
    overflow            : hidden;
    z-index             : 10100;
  } /* #roadsign-menuContainer */


  #roadsign-inoutgoA {     
    position          : absolute;
    top               : 5%; 
    right             : 0px;
    width             : 256px;
    max-width         : 21%;
    height            : auto;
    z-index           : 10240;     /* ensure image above any animations */
  } /* #roadsign-inoutgoA */
  
  #roadsign-inoutgoA-message {      /* the inoutgo message dialog maps over (#roadsign-inoutgoA) */
    position          : absolute;
    top               : 5%;
    right             : 0px;
    width             : 100%;
    z-index           : 10241;      /* overlays (#roadsign-inoutgoA) */
    background-color  : transparent; 
  } /* #roadsign-inoutgoA-message */
  
                                    /* Let the message thumbnail ride in the message dialog */
                                    /* it should map exactly over the inoutgoA image        */
  #roadsign-inoutgoA-message-thumbnail { 
    position          : static;
    float             : right;
    top               : 0px;
    right             : 0px;
    width             : 256px;
    max-width         : 21%;
    height            : auto;
    z-index           : 10242;      /* overlays (#roadsign-inoutgoA) */
  }

  #roadsign-inoutgoA-message-textbox {
    display           : inline-block;
    width             : 70%;
    margin            : 8px;
    padding           : 8px;
  }
  
  #roadsign-inoutgoB {     
    position          : absolute;
    top               : 50%; 
    right             : 0px;
    width             : 256px;
    max-width         : 21%;
    height            : auto;
    z-index           : 10240;     /* ensure image above any animations */
  } /* #roadsign-inoutgoB */
  
  .roadsign-brand {
    position          : absolute;
    top               : 4px;
    right             : 172px;     /* should be 4px greater than #roadsign-ingo.width */
    width             : 64px;
    max-width         : 25%;
    height            : auto;
    z-index           : 5000;
  }
}  /** Wide **/


/**********/
/* NARROW */
/**********************************************************************************************/
/* A description of the Road Sign as it is to be displayed on a narrow viewport,              */
/* typically a phone.                                                                         */
/**********************************************************************************************/
@media not all and (min-width: 750px) {   
  #roadsign {     
    position          : absolute;
    top               : 59px;       /* top pixel at narrow-wide transition */
    right             : 0px;
    left              : 0px;
    overflow          : hidden;  
    z-index           : 10000;     /* ensure image above any animations */
  }
  
  #roadsign-menuContainer {
    position            : absolute;
    top                 : -196px;
    left                : 45px;
    padding-top         : 20vw;           /* here's the trick, decrease padding as window width decreases */
    width               : 90%;
    height              : 256px;
    max-width           : 256px;
    overflow            : hidden;
    z-index             : 10100;
  } /* #roadsign-menuContainer */


  #roadsign-inoutgoA {     
    position          : absolute;
    top               : 61vw;
    left              : 4%;
    width             : 256px;
    max-width         : 44%;
    height            : auto;
    z-index           : 10240;     /* ensure image above any animations */
  } /* #roadsign-inoutgoA */
  
  #roadsign-inoutgoA-message {      /* ensure a mapping over (#roadsign-inoutgoA) */
    position          : absolute;
    top               : 61vw;
    left              : 4%;
    width             : 96%;
    z-index           : 10241;      /* overlays (#roadsign-inoutgoA) */
    background-color  : transparent; 
  }
  
                                    /* Let the message thumbnail ride in the message dialog */
                                    /* it should map exactly over the inoutgoA image        */
  #roadsign-inoutgoA-message-thumbnail { 
    position          : static;
    float             : left;
    width             : 256px;
    height            : auto;
    max-width         : 45.76%;     /* (1 - 0.96) (max-width of parent) * (0.44 (max-width of grandparent) */
    z-index           : 10242;      /* overlays (#roadsign-inoutgoA) */
  }
  
  #roadsign-inoutgoA-message-textbox { 
    display           : inline-block;
    margin            : 8px;
    padding           : 8px;
  }
  
  #roadsign-inoutgoB {     
    position          : absolute;
    top               : 61vw; 
    right             : 4%;
    width             : 256px;
    max-width         : 44%;
    height            : auto;
    z-index            : 10240;     /* ensure image above any animations */
  } /* #roadsign-inoutgoB */
  
  .roadsign-brand {
    position          : absolute;
    top               : 4px;
    right             : 4px;
    width             : 80px;
    max-width         : 25%;
    height            : auto;
    z-index           : 5000;
  }  
}
  
.roadsign-image {
  top               : 0;
  left              : 0;
  width             : 100%;
  height            : 100%;
}

.roadsign-menu-image {
  position          : absolute;
  top               : 0;
  left              : 0; 
  width             : 100%;
  height            : auto;
  z-index           : 5000;
}


/************/
/* My Story */
/**********************************************************************************************/
/* The road sign and road sign animation.                                                     */
/**********************************************************************************************/
#mystory-page {              /* entire My Story page */
  position          : fixed;
  top               : 0;
  right             : 0;
  bottom            : 0;
  left              : 0;
  background-image  :  url("./mystory/assets/images/text-bg-rwcs-verylightt.png");
  background-repeat : repeat;
  background-size   : 256px 256px;
  width             : 100%;
  height            : 100%;
} /* mystory-page */


/**********************************************************************************************/
/* The My Story header includes the My Story image                                            */
/* For aesthetics, it is important that the My Story scroll appears to flow "underneath" the  */
/* header.  All dimensions exist in viewport measures for scalability.                        */
/**********************************************************************************************/
#mystory-headerBlock {
  top               : 8px;
  left              : 10vw;
  width             : 80vw;
  height            : 40vw ;
  max-width         : 800px;
  max-height        : 400px;  
  z-index           : 2000;
} /* mystory-headerBlock */

#mystory-brand {
  position          : absolute;
  top               : 1vw;
  right             : 1vw;
  width             : 8vw;
  height            : auto;
} /* mystory-header */

.mystory-block {              /* one block in My Story */
  background-image  :  url("./mystory/assets/images/text-bg-darkt.png");
  background-repeat : repeat;
  background-size   : 256px 256px;
}

 










